import { IServicePageResponse } from '../../../api.interface'

export default <IServicePageResponse>{
  serviceType: 'easter',
  formId: 'Easter%20offerings%20Denmark',
  title: 'Easter',
  subTitle: 'Bunny kisses and Easter wishes.',
  theme: 'spring',
  icon: '/icons/easter.svg',
  image: '/assets/images/services/easter/easter_illustration.svg',
  sections: [
    {
      sectionId: 'hero',
      label: 'Easter',
      title: 'Bunny kisses and Easter wishes.',
      description:
        'There’s always a reason to celebrate a holiday. Let’s make the most out of the true holiday that welcomes spring time.',
      image: '/assets/images/services/easter/easter_illustration.svg',
      icon: '/icons/easter.svg',
      buttonText: 'Request a proposal',
    },
    {
      sectionId: 'options',
      title: 'All you need for Easter',
      description: '',
      optionsItems: [
        {
          icon: '/assets/images/services/easter/service_1.svg',
          title: 'Easter presents',
          description:
            'Easter eegs, gift baskets and everything else your heart desires.',
        },
        {
          icon: '/assets/images/services/easter/service_2.svg',
          title: 'Virtuel events',
          description:
            'Book everything from a virtual cocktail .',
        },
      ],
    },
    {
      sectionId: 'journey',
      title: 'Easter hapiness is just a few steps away',
      description: '',
      journeyItems: [
        {
          icon: '/assets/images/services/easter/journey_1.svg',
          title: 'Tell us what you like',
          description: 'We have a vast variety of everything. Tell us how you want to spoil your colleagues!',
        },
        {
          icon: '/assets/images/services/easter/journey_2.svg',
          title: 'Receive proposal',
          description: 'We’ll create a detailed proposal!',
        },
        {
          icon: '/assets/images/services/easter/journey_3.svg',
          title: 'Accept!',
          description: 'That’s it! You’re now ready to do bunny jumps!',
        },
      ],
    },
    {
      sectionId: 'benefits',
      title: 'Why companies love celebrating Easter with Good Monday',
      description: '',
      image: '/assets/images/services/easter/benefits_illustration.svg',
      benefitsItems: [
        {
          icon: 'eva eva-bulb-outline',
          title: 'Inspiration',
          description: 'We’ve spend tons of time gathering all th best Easter gift ideas out there.',
        },
        {
          icon: 'eva eva-clock-outline',
          title: 'No time wasted',
          description: 'No more going back and forth to find the right price and quality.',
        },
        {
          icon: 'eva eva-gift-outline',
          title: 'Company wide',
          description: 'We can ship the presents directly to your colleagues.',
        },
      ],
    },
    {
      sectionId: 'cta',
      title: 'Did you know that Good Monday does all season holiday gifting and not just Easter?',
      description: '',
      image: '/assets/images/services/cta_illustration.svg',
      buttonText: 'Request a proposal',
    },
  ],
}
